<template>
  <div>
    <flickity
      ref="flickity"
      :options="flickityOptions"
      :class="{ 'flicky-container': container }"
      @init="onFlickityInit"
    >
      <div
        class="carousel-cell"
        v-for="(card, i) in cards"
        :key="i"
        :class="{ 'cancel-clicks': isCarouselDragged }"
      >
        <slot name="card" :card="card"></slot>
      </div>
    </flickity>

    <div class="d-flex justify-content-start buttons-wrapper">
      <a
        href="#"
        class="z-icon-wrapper -medium border"
        @click.prevent="previous()"
      >
        <svg-arrow-right-icon class="left-icon w-color-primary" />
      </a>
      <a href="#" class="z-icon-wrapper -medium border" @click.prevent="next()">
        <svg-arrow-right-icon class="w-color-primary" />
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    flickity: () => import("vue-flickity"),
    "svg-arrow-right-icon": () => import("/assets/icons/arrowRight.svg?inline"),
  },

  props: {
    cards: {
      type: Array,
      required: true,
    },
    container: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },

  data() {
    return {
      flickityOptions: {
        prevNextButtons: false,
        cellAlign: "left",
        draggable: true,
        freeScroll: true,
      },
      isCarouselDragged: false,
    };
  },

  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },

    onFlickityInit() {
      // prevent clicking on carousel cards while dragging
      this.$refs.flickity.on(
        "dragStart",
        () => (this.isCarouselDragged = true)
      );
      this.$refs.flickity.on("dragEnd", () => (this.isCarouselDragged = false));

      setTimeout(
        function () {
          if (this.$refs.flickity) this.$refs.flickity.reloadCells();
        }.bind(this),
        1000
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.flicky-container {
  padding: 0 1rem;
  width: calc(100vw - ((100vw - 1140px) / 2));
  max-width: 100vw;
}

.buttons-wrapper {
  padding: 0 1rem;
  width: 7.0625rem;

  .left-icon {
    transform: rotate(180deg);
  }

  .z-icon-wrapper {
    z-index: 1000;

    &:not(:first-of-type) {
      margin-left: 0.8125rem;
    }
  }
}

::v-deep() {
  .flickity-page-dots {
    display: flex;
    align-items: center;
    margin-left: 7.5rem;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      background: var(--a-color-blue-light, #333);
      opacity: 1;

      &.is-selected {
        background: var(--a-color-primary, #333);
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  /* NOte: Useful to hide messy initial state */
  .flickity-viewport {
    height: 28.625rem;
    overflow: hidden;
  }

  .z-card {
    align-self: stretch;
  }
}

.cancel-clicks {
  pointer-events: none;
}

.carousel-cell {
  margin-right: 1.875rem;
  min-height: 100%;
  display: flex;
}

@media only screen and (min-width: 576px) {
  ::v-deep() .z-card {
    max-width: 27.375rem !important;
    min-width: 27.375rem !important;
  }
}

@media only screen and (max-width: 575px) {
  ::v-deep() .z-card {
    max-width: 18.75rem !important;
    min-width: 18.75rem !important;
  }
}
</style>
